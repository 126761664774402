<template>
  <page-content>

    <common-table
        ref="table"
        path="web/machine/product"
        :columns="columns"
        :rowSelectable="true"
    >

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
        <a-button ghost type="danger" @click="batchDelete">批量删除</a-button>
        <a-button @click="clickExport">
          <a-icon type="export"/>
          导出Excel
        </a-button>
        <a-upload
            name="file"
            :multiple="false"
            :showUploadList="false"
            :action="importUrl"
            @change="handleChange">
          <a-button @click="clickImport">
            <a-icon type="import"/>
            导入Excel
          </a-button>
        </a-upload>
      </template>

      <template slot="icon" slot-scope="{record}">
        <a-avatar shape="square" :size="40" :src="record.logo">{{ record.name }}</a-avatar>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <product-edit ref="productEdit" @success="getList"></product-edit>
  </page-content>
</template>

<script>

import config from "../../../config/config"
import ProductEdit from './ProductEdit'

export default {
  name: 'Product',
  components: {
    ProductEdit
  },
  data() {
    return {
      headers: {
        authorization: 'authorization-text',
      },
      brandList: [],
      classifyList: [],
      importUrl: config.apiServer + 'web/machine/product/import'
    }
  },
  created() {
    this.$get("web/machine/brand/all").then(suc => {
      let list = []
      suc.data.forEach(item => {
        list.push({
          text: item.name,
          value: item.id
        })
      })
      this.brandList = list
    })

    this.$get("web/machine/classify/all").then(suc => {
      let list = []
      suc.data.forEach(item => {
        let children = []
        item.children.forEach(child => {
          children.push({
            text: child.name,
            value: child.id
          })
        })
        list.push({
          text: item.name,
          value: item.id,
          children: children
        })
      })
      this.classifyList = list
    })
  },

  computed: {
    columns() {
      return [
        {
          title: '产品图片',
          dataIndex: 'productIcon',
          scopedSlots: {customRender: 'icon'}
        },
        {
          title: '产品名称',
          dataIndex: 'name',
          sorter: true
        },
        {
          title: '品牌',
          dataIndex: 'brandId',
          filters: this.brandList,
          filterMultiple: false,
          customRender(text, record) {
            return record.brandName
          },
        },
        {
          title: '分类',
          dataIndex: 'classifyId2',
          customRender(text, record) {
            return record.classifyName1 + "/" + record.classifyName2
          },
          filters: this.classifyList,
          filterMultiple: true,
        },
        {
          title: '商品编码',
          dataIndex: 'sn',
          sorter: true
        },
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '二维码前缀',
          dataIndex: 'qrPrefix',
          sorter: true
        },
        {
          title: '件号',
          dataIndex: 'partNo',
          sorter: true
        },
        {
          title: '产地',
          dataIndex: 'origin',
          sorter: true
        },
        {
          title: '奖励',
          dataIndex: 'priceReward',
          customRender(text, record) {
            return text / 100
          }
        },
        // {
        //     title: '供应商',
        //     dataIndex: 'supplier',
        //     sorter: true
        // },
        // {
        //     title: '商品ID',
        //     dataIndex: 'mallGoodsId',
        //     sorter: true
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success("导入成功");
        this.getList()
      } else if (info.file.status === 'error') {
        this.$message.error("导入失败");
      }
    },
    add() {
      this.$refs.productEdit.show()
    },
    edit(record) {
      this.$refs.productEdit.show(record)
    },
    view(record) {
      this.$router.push("/machine/item/" + record.id)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/product/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    batchDelete() {
      let idArr = this.$refs.table.selectedRowKeys
      if (idArr.length == 0) {
        return
      }
      let that = this
      this.$confirm({
        title: `是否删除选中的${idArr.length}个产品?`,
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/product/', {ids: idArr.join(",")}).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })

    },
    getList() {
      this.$refs.table.getData()
    },
    clickImport() {

    },
    clickExport() {
      this.$refs.table.export("产品信息")
    }
  }
}
</script>

<style scoped>

</style>
